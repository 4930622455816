const swiperKV = new Swiper('.top202403kv__swiper', {
  loop: true,
  speed: 1500,
  effect: 'fade',
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.top202403kv__swiper .swiper-pagination',
  },
  navigation: {
    nextEl: '.top202403kv__swiper .swiper-button-next',
    prevEl: '.top202403kv__swiper .swiper-button-prev',
  },
});


// news tab
jQuery(function($){
  $(function(){
    if($('.top202403news__tab').length){

      var splitNum = location.search.substr(1).split(/&/);
      var list = new Object();
      var i = 0;
      for(i=0;i<splitNum.length;i++){
        var localsplit = splitNum[i].split(/=/);
        list[localsplit[0]] = localsplit[1];
      }

      $('.top202403news__tab').each(function (){
        var tabMenu = $(this).find('[role="tab"]');
        var tabPanel = $(this).find('[role="tabpanel"]');

        if(list["tab"] && list["tab"].search(/^[-]?[0-9]+$/) == 0 && tabMenu[list["tab"]] ){
          tab( $(tabMenu[list["tab"]]) );
        }else if( !tabMenu.is('[aria-expanded="true"]') ){
          tab( $(tabMenu[0]) );
        }

        tabMenu.on('click', function() {
          tab($(this));
          return false;
        });
        tabMenu.on("keydown", function(e) {
          if (e.keyCode == 13){
            $(this).trigger("click");
          }else if (e.keyCode == 39){
            $(this).parent().next().children().focus().trigger("click");
          }else if (e.keyCode == 37){
            $(this).parent().prev().children().focus().trigger("click");
          }
        });

        function tab(obj){
          if(obj.attr('aria-expanded') == 'false'){
            tabMenu.attr('aria-expanded', 'false');
            obj.attr('aria-expanded', 'true');
            tabPanel.hide();
            tabPanel.eq(obj.parent().index()).attr('aria-expanded', 'true').fadeIn();
            if(typeof set_image_size == "function") set_image_size();
          }
        }
      });
    }
  });
});

//news event swiper
const swiperNewsEvent = new Swiper('.top202403news__tab__body.--event .swiper', {
  loop: true,
  speed: 1500,
  slidesPerView: 1,
  /* autoHeight: true, */
  breakpoints: {
    769: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
  pagination: {
    el: '.top202403news__tab__body.--event .swiper-pagination',
  },
  navigation: {
    nextEl: '.top202403news__tab__body.--event .swiper-button-next',
    prevEl: '.top202403news__tab__body.--event .swiper-button-prev',
  },
});

//news info swiper
const swiperNewsInfo = new Swiper('.top202403news__tab__body.--news .swiper', {
  loop: true,
  speed: 1500,
  slidesPerView: 1,
  /* autoHeight: true, */
  breakpoints: {
    769: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
  pagination: {
    el: '.top202403news__tab__body.--news .swiper-pagination',
  },
  navigation: {
    nextEl: '.top202403news__tab__body.--news .swiper-button-next',
    prevEl: '.top202403news__tab__body.--news .swiper-button-prev',
  },
});

//message slide
const swiperSlides = document.getElementsByClassName("swiper-slide-message");
const breakPoint = 768;
let swiper;
let swiperBool;

window.addEventListener(
  "load",
  () => {
    if (breakPoint < window.innerWidth) {
      swiperBool = false;
    } else {
      createSwiper();
      swiperBool = true;
    }
  },
  false
);

window.addEventListener(
  "resize",
  () => {
    if (breakPoint < window.innerWidth && swiperBool) {
      swiper.destroy(false, true);
      swiperBool = false;
    } else if (breakPoint >= window.innerWidth && !swiperBool) {
      createSwiper();
      swiperBool = true;
    }
  },
  false
);

const createSwiper = () => {
  swiper = new Swiper(".swiper-message", {
    loop: true,
    speed: 1500,
    /* autoplay: {
      // 自動再生
      delay: 1000, // 1秒後に次のスライド
      disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
    }, */
    pagination: {
      el: ".swiper-pagination-message",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next-message",
      prevEl: ".swiper-button-prev-message",
    },
  });
};